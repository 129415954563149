















import {Vue, Component} from 'vue-property-decorator';
import {to, hasClaim} from '@/utils';
import AidePage1 from '@/components/Aide/AidePage1.vue';
import {UserModule} from '@/store/modules/user';
import {postHelpFile} from '@/api/help';
import {ApplicationModule} from '@/store/modules/application';

@Component({
    components: {
        AidePage1,
    },
})
export default class extends Vue {
    public helpFile: File | null = null;
    public get showUpdateModule() {
        const {claims} = UserModule;
        if (
            !claims ||
            !Array.isArray(claims) ||
            claims.length === 0 ||
            !hasClaim(claims, 'MyPyrop_HelpFile', 'Update')
        ) {
            return false;
        }
        return true;
    }

    public async handleClickUpdate(): Promise<void> {
        const safeNameStr = this.helpFile?.name ?? '';
        if (!this.helpFile || !safeNameStr.toLowerCase().endsWith('.md')) {
            return;
        }

        ApplicationModule.setLoading(true);
        const formData = new FormData();
        formData.append('image', this.helpFile, this.helpFile.name);
        await to(postHelpFile(formData, 'page1'));
        ApplicationModule.setLoading(false);
        this.helpFile = null;
        ApplicationModule.showSnackbar({
            displayed: true,
            text: 'Fichier mis à jour avec succès, veuillez attendre environ 15 minutes pour voir les changements...',
            color: 'success',
            timeout: 15 * 1000
        });
    }
}
