













import {Vue, Component} from 'vue-property-decorator';
import md from 'markdown-it';

@Component({})
export default class extends Vue {
    private renderedMarkdown: string | null = null;

    private mounted() {
        if (!this.$options.components) {
            return;
        }

        // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
        const content = require('@/assets/md/AidePage1.md').default;

        const mdit = md({
            breaks: true,
        });
        mdit.disable('code');

        var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        if (!isIOS) {
            // On android device, it's better to open pdf in a new window
            // Remember old renderer, if overridden, or proxy to default renderer
            var defaultRender =
                mdit.renderer.rules.link_open ||
                function (tokens, idx, options, env, self) {
                    return self.renderToken(tokens, idx, options);
                };

            mdit.renderer.rules.link_open = function (tokens, idx, options, env, self) {
                // If you are sure other plugins can't add `target` - drop check below
                var aIndex = tokens[idx].attrIndex('target');

                if (aIndex < 0) {
                    tokens[idx].attrPush(['target', '_blank']); // add new attribute
                } else {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    tokens[idx].attrs![aIndex][1] = '_blank'; // replace value of existing attr
                }

                // pass token to default renderer.
                return defaultRender(tokens, idx, options, env, self);
            };
        }

        this.renderedMarkdown = mdit.render(content);
    }
}
